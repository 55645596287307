import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lxlabs/change-ad-components';

const LegalSection = ({ title, children }) => (
  <>
    <Title size="small" spacing={{ bottom: 'small', top: 'large' }}>{title}</Title>
    {children}
  </>
);

export default LegalSection;

LegalSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
